const SettingsMenu = [
  {
    heading: 'Menu Action Setting',
    route: '/menu-action',
    pages: [
      {
        heading: 'Menu',
        route: '/menu-action/menu',
        fontIcon: 'fas fa-tint',
        svgIcon: 'media/icons/duotune/general/gen025.svg',
      },
      {
        heading: 'Action',
        route: '/menu-action/action',
        fontIcon: 'fas fa-users',
        svgIcon: 'media/icons/duotune/general/gen025.svg',
      },
    ],
  },
];

export default SettingsMenu;
